import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ServerURL } from "../../urls";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const SuccessStories = () => {
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalDocs, setTotalDocs] = useState();
  const [imageLoaded, setImageLoaded] = useState({}); 

  const navigate = useNavigate();
  const handleImageLoad = (id) => {
    setImageLoaded((prev) => ({ ...prev, [id]: true }));
  };

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await axios.get(`${ServerURL}/landingPage/get-all-stories`);
        setStories(response.data.docs || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStories();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Customer Success Stories - EnLume</title>
        <meta
          name="description"
          content="Customer Success Stories showcasing the work of EnLume Technologies."
        />
      </Helmet>
      <Helmet>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=G-L50781CCXR`}
            ></script>
            <script>
              {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-L50781CCXR');
          `}
            </script>
          </Helmet>
      <section
        className="inner_banner_section"
        style={{
          backgroundImage:
            "url('https://payload.enlume.com/media-images/success-stories-min.jpg')",
        }}
      >
        <div className="inner_banner_blk">
          <div className="container">
            <div className="row w100">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1>Customer Success Stories - Transformation Tales</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="success_stories_section">
        <div className="container">
          <div className="row success_stories_blk">
            {isLoading
              ? // Show only skeletons while loading stories
                [...Array(12)].map((_, index) => (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                    <div className="success_story_box skeleton-box">
                      <Skeleton height={100} width="100%" />
                      <p className="success_img_top_text">
                        <Skeleton  width="100%" />
                      </p>
                      <a className="success_txt">
                        <Skeleton width="100%" />
                      </a>
                    </div>
                  </div>
                ))
              : error
              ? // Show error message
                <div className="alert alert-danger text-center w-100">
                  Failed to load success stories. Please try again later.
                </div>
              : stories.length > 0
              ? // Display actual stories
                stories
                .filter((story) => !story.pageTitle.includes("success-stories"))
                .map((story) => (
                  <div key={story.id} className="col-lg-4 col-md-6 col-sm-12">
                    <section
                        className="success_story_box"
                        onClick={() => navigate(`/success-stories/${story.pageTitle}/`)}
                        style={{
                          backgroundImage: !imageLoaded[story.id]
                            ? "none" // No background until loaded
                            : `url(${story.thumbNail || "https://landingpage.enlume.com/media-images/learning-platform-thumbnail.png"})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: "200px",
                          position: "relative",
                          cursor:"pointer",
                        }}
                      >
                        {/* Show Skeleton while the image is loading */}
                        {!imageLoaded[story.id] && (
                          <Skeleton height={200} width="100%" style={{ position: "absolute", top: 0 }} />
                        )}
                        <img
                          src={
                            story.thumbNail ||
                            "https://landingpage.enlume.com/media-images/learning-platform-thumbnail.png"
                          }
                          alt={story.thumbNailDescription || "Story Thumbnail"}
                          style={{ display: "none" }} // Hide actual img tag
                          onLoad={() => handleImageLoad(story.id)}
                        />

                      <p className="success_img_top_text">
                        {story.thumbNailDescription || "No description available."}
                      </p>
                      <a
                        href={`/success-stories/${story.pageTitle}/`}
                        className="success_txt"
                        onClick={(e) => e.stopPropagation()} // Prevents card click event
                      >
                        {story.thumbNailLabel || "Read More"}
                      </a>
                    </section>
                  </div>
                ))
              : // No data available
                <p className="text-center w-100">No success stories available.</p>}
          </div>
        </div>
      </section>
    </div>
  );
};
